import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserType } from "../../enums/UserType";
import IPrivateRoute from "../../interfaces/IPrivateRoute";

const AdminRoute = (props: IPrivateRoute) => {

    
    const navigate = useNavigate()
    const location = useLocation()
    const [isLoading, setLoading] = useState<boolean>(true)

    const loader = () => {
       const login = window.sessionStorage.getItem("hash")
       const type = window.sessionStorage.getItem("type")

        if (login === null || type === UserType.STUDENT || login === null || type === UserType.SCHOOL){
            return navigate("/", {
            state: {
              path: location.pathname
            }
          });
        
        }
        setLoading(false)
    }

    useEffect(() => {
     loader()
  }, [])

    return (
      <div>
      {!isLoading &&
       props.element
      }
    </div>
      )
}

export default AdminRoute