import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import loginService from "../../services/LoginService";
import "bootstrap/dist/css/bootstrap.min.css";

import "./sidebar.scss";
import { UserType } from "../../enums/UserType";

const sidebarNavItems = [
  {
    display: "Escolas",
    icon: <i className="bx bx-network-chart"></i>,
    to: "/list-schools",
    section: "",
  },
];
const adminNavItems = [
  {
    display: "Usuários",
    icon: <i className="bx bx-network-chart"></i>,
    to: "/list-users",
    section: "",
  },
];

const wordGallowsNavItems = [
  {
    display: "Jogo da Forca",
    icon: <i className="bx bx-network-chart"></i>,
    to: "/list-words-gallows",
    section: "",
  },
];

const quizQuestionsNavItems = [
  {
    display: "Quiz de Perguntas",
    icon: <i className="bx bx-network-chart"></i>,
    to: "/list-quiz-questions",
    section: "",
  },
];

const crusadeNavItems = [
  {
    display: " Palavra Cruzada",
    icon: <i className="bx bx-network-chart"></i>,
    to: "/list-crusades",
    section: "",
  },
];

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const [login, setLogin] = useState(window.sessionStorage.getItem("hash"));
  const [type, setType] = useState(window.sessionStorage.getItem("type"));
  const [menuClosed, setMenuClosed] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    loginService.logout();
    window.sessionStorage.removeItem("hash");
    window.sessionStorage.removeItem("type");
    setLogin(null);
    navigate("/");
  };

  useEffect(() => {
    const currentPath = window.location.pathname.split("/")[1];
    const activeItem = adminNavItems.findIndex(
      (item) => item.to.indexOf(currentPath) >= 0
    );
    setActiveIndex(currentPath.length === 0 ? 0 : activeItem);
  }, [location]);

  const handleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const closeSidebar = () => {
    // eval("document.getElementsByClassName('link-collapse')[0].click()");
    const linkCollapseButton = document.querySelector(
      ".link-collapse"
    ) as HTMLButtonElement | null;
    if (linkCollapseButton) {
      linkCollapseButton.click();
    }
    setMenuClosed(true);
  };

  const resetMenuClosed = () => {
    setMenuClosed(false);
  };

  const allNavItems = [
    ...sidebarNavItems,
    ...adminNavItems,
    ...wordGallowsNavItems,
    ...quizQuestionsNavItems,
    ...crusadeNavItems,
  ];

  // const handleMenuItemClick = () => {
  //   setCollapsed(true);
  // }

  return (
    <div className="sidebar" style={{ left: collapsed ? -250 : 0 }}>
      {collapsed && (
        <>
          <a
            href="javascript: void(0)"
            onClick={() => handleSidebar()}
            className="link-collapse btn btn-primary"
          >
            Menu
          </a>
        </>
      )}

      {!collapsed && (
        <>
          <a
            href="javascript: void(0)"
            onClick={() => {
              handleSidebar()
              resetMenuClosed()
            }}
            className="link-collapse"
            
          >
            Fechar
          </a>
          <div
            className="sidebar__menu"
            style={{ borderRight: "1px solid #006699" }}
          >
            <>
              <div className="sidebar__menu__indicator"></div>
              {type === UserType.ADMIN &&
                allNavItems.map((item, index) => (
                  <Link to={item.to} 
                  key={index} 
                  onClick={() => {
                  closeSidebar();
                  // handleMenuItemClick();
                  }}>
                    <div
                      className={`sidebar__menu__item ${
                        activeIndex === index ? "active" : ""
                      }`}
                    >
                      <div className="sidebar__menu__item__icon">
                        {item.icon}
                      </div>
                      <div className="sidebar__menu__item__text">
                        {item.display}
                      </div>
                    </div>
                  </Link>
                ))}
            </>
          </div>
          <div className="login">
            {!login ? (
              <Link to="/login" className="btn btn-primary">
                Logar
              </Link>
            ) : (
              <button onClick={handleLogout} className="btn btn-primary ">
                Sair
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
