import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import IWordGallows from "../../interfaces/IWordGallows";
import { WordGallowsType } from "../../enums/WordGallowsType";
import wordGallowsService from "../../services/WordGallowsService";

registerLocale("pt-BR", ptBR);

const WordGallowsList = () => {
  const [items, setItems] = useState<IWordGallows[]>([]);
  const [showWords, setShowWords] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [wordId, setWordId] = useState<number>();
  const [word, setWord] = useState<string>();
  const [tip, setTip] = useState<string>();
  const [level, setLevel] = useState<string | undefined>("Fácil");
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [wordError, setWordError] = useState<boolean>(false);

  const loadList = () => {
    setLoading(true);
    wordGallowsService
      .getAll()
      .then((response) => {
        let words: IWordGallows[] = response.data;
        setItems(words);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadList();
  }, []);

  const handleShowUpdate = (word: IWordGallows) => {
    setWordId(word.id);
    setWord(word.word);
    setTip(word.tip);
    setLevel(word.difficultyLevel);
    handleShow();
  };

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
    var wordRegex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/;
    if (word && wordRegex.test(word)) {
      let data = {
        word: word,
        tip: tip,
        difficultyLevel: level,
      };
      if (wordId) {
        wordGallowsService
          .update(data, wordId)
          .then((response) => {
            loadList();
            handleClose();
            setWordId(undefined);
          })
          .catch((error) => {
            setError(true);
          });
      } else {
        wordGallowsService
          .create(data)
          .then((response) => {
            loadList();
            handleClose();
          })
          .catch(() => {
            setError(true);
          });
      }
    } else {
      setWordError(true);
    }
  };

  const handleDelete = async (id: number) => {
    wordGallowsService
      .delete(id)
      .then((response) => {
        loadList();
        handleClose();
      })
      .catch((error) => {
        alert("Não foi possível excluir a palavra");
      });
  };

  const handleClose = () => {
    setWordId(undefined);
    setWord(undefined);
    setTip(undefined);
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    setError(false);
  };

  const handleCloseWords = () => {
    setShowWords(false);
  };


  return (
    <>
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Palavra da Forca</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={handleShow}>
              Cadastrar palavra
            </Button>
          </div>

          <br />
          <Table striped bordered hover>
            <thead>
              <tr className="tr">
                <th>Palavra</th>
                <th>Dica</th>
                <th>Nível</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {items.map((word: IWordGallows) => (
                <tr key={word.id}>
                  <td style={{ width: 100 }}>
                    <span>{word.word}</span>
                  </td>
                  <td>
                    <span>{word.tip}</span>
                  </td>
                  <td>
                    <span>{word.difficultyLevel}</span>
                  </td>
                  <td style={{ width: 250, paddingLeft: 15 }}>
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={(e) => handleShowUpdate(word)}
                    >
                      Atualizar
                    </button>
                    <button
                      className="btn btn-danger"
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={(e) => handleDelete(word.id)}
                    >
                      Deletar
                    </button>
                  </td>
                </tr>
              ))}
              {items.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Nenhuma escola encontrada.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar Palavra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Palavra</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Palavra"
                value={word}
                onChange={(e) => setWord(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Dica</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Dica"
                value={tip}
                onChange={(e) => setTip(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Nível</Form.Label>

              <Form.Select onChange={(e) => setLevel(e.target.value)}>
                {level === "Facil" && (
                  <>
                    <option selected value={WordGallowsType.FACIL}>
                      Fácil
                    </option>
                    <option value={WordGallowsType.MEDIO}>Médio</option>
                    <option value={WordGallowsType.DIFICIL}>Difícil</option>
                  </>
                )}

                {level === "Medio" && (
                  <>
                    <option value={WordGallowsType.FACIL}>Fácil</option>
                    <option selected value={WordGallowsType.MEDIO}>
                      Médio
                    </option>
                    <option value={WordGallowsType.DIFICIL}>Difícil</option>
                  </>
                )}
                {level === "Dificil" && (
                  <>
                    <option value={WordGallowsType.FACIL}>Fácil</option>
                    <option value={WordGallowsType.MEDIO}>Médio</option>
                    <option selected value={WordGallowsType.DIFICIL}>
                      Difícil
                    </option>
                  </>
                )}
                {
                  level !== "Facil" && level !== "Medio" && level !== "Dificil" && (
                    <>
                    <option value={WordGallowsType.FACIL}>Fácil</option>
                    <option value={WordGallowsType.MEDIO}>Médio</option>
                    <option value={WordGallowsType.DIFICIL}>Difícil</option>
                  </>
                  )             
                }
              </Form.Select>
            </Form.Group>
          </Form>
          {error && (
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              Palavra já cadastrada
            </Alert>
          )}
          {wordError && word !== undefined && (
            <Alert
              variant="danger"
              onClose={() => setWordError(false)}
              dismissible
            >
              Palavra inválida
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              setWordId(undefined);
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => handleClick(e)}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showWords} onHide={handleCloseWords}>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseWords}>
            Cancelar
          </Button>
          {
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={handleClick}
            >
              Salvar
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WordGallowsList;
