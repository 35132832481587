import api from "./api";

class QuizQuestionsService {
  async getAll(page: number, searchTerm?: string): Promise<any> {
    try {
      const params = searchTerm ? {page: page,  searchTerm: searchTerm } : {page: page};
    //   console.log(params, 'params service front')
      return api
        .get(`/quiz-questions/all`, { params })
        .then((result) => {
            // console.log(result, 'result service front')
          return Promise.resolve(result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("Error from API:", error);
      return Promise.reject(error);
    }
  }

  async create(data: any): Promise<any> {
    return api({
      url: "/quiz-questions/create",
      method: "POST",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async update(data: any, id: any): Promise<any> {
    return api({
      url: `/quiz-questions/update/${id}`,
      method: "PUT",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async delete(id: any): Promise<any> {
    return api
      .delete(`/quiz-questions/delete/${id}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const quizQuestionService = new QuizQuestionsService();
export default quizQuestionService;
