import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { PatternFormat } from "react-number-format";
import ICrusade from "../../interfaces/ICrusade";
import { CrusadeLevelType } from "../../enums/CrusadeType";
import crusadeService from "../../services/CrusadeService";
import UtilDate from "../../utils/util.date";  
import { useNavigate,useLocation } from "react-router-dom";



registerLocale("pt-BR", ptBR);

const CrusadeList = () => {
  const [items, setItems] = useState<ICrusade[]>([]);
  const [showCrusades, setShowCrusades] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [crusadeId, setCrusadeId] = useState<number>();
  const [title, setTitle] = useState<string>();
  const [level, setLevel] = useState<string | undefined>("Fácil");
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [wordError, setWordError] = useState<boolean>(false);
  const navigate = useNavigate();

  const loadList = () => {
    setLoading(true);
    crusadeService
      .getAll()
      .then((response) => {
        let crusade: ICrusade[] = response.data;
        setItems(crusade);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadList();
  }, []);

  const handleShowUpdate = (crusade: ICrusade) => {
      navigate("/registerCrusade", {
        state:  {
          crusade : crusade
        }
      })
  };

  const registerCrusade = () => {
    navigate("/registerCrusade");
  }

  const handleDelete = async (id: number) => {
    crusadeService
      .delete(id)
      .then((response) => {
        loadList();
        handleClose();
      })
      .catch((error) => {
        alert("Não foi possível excluir a cruzada");
      });
  };

  const handleClose = () => {
    setCrusadeId(undefined);
    setTitle(undefined);
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    setError(false);
  };


  return (
    <>
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Cruzadas</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={registerCrusade}>
              Cadastrar cruzada
            </Button>
          </div>

          <br />
          <Table striped bordered hover>
            <thead>
              <tr className="tr">
                <th>Título</th>
                <th>Criado em</th>
                <th>Nível</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {items.map((crusade: ICrusade) => (
                <tr key={crusade.id}>
                  <td>
                    <span>{crusade.title}</span>
                  </td>
                  <td>
                    <span>
                      {UtilDate.stringToDMYHM(crusade.createdAt.toString())}
                    </span>
                  </td>
                  <td>
                    <span>{crusade.difficultyLevel}</span>
                  </td>
                  <td style={{ width: 250, paddingLeft: 15 }}>
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={(e) => handleShowUpdate(crusade)}
                    >
                      Atualizar
                    </button>
                    <button
                      className="btn btn-danger"
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={(e) => handleDelete(Number(crusade.id))}
                    >
                      Deletar
                    </button>
                  </td>
                </tr>
              ))}
              {items.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Nenhuma cruzada encontrada.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default CrusadeList;
