import React, { useState } from "react";
import { Button, Modal, Form, Table} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import IWord from "../../interfaces/ICrusadeWord";
import crusadeService from "../../services/CrusadeService";
import { CrusadeLevelType } from "../../enums/CrusadeType";
import { useLocation, useNavigate } from "react-router-dom";
import ICrusade from "../../interfaces/ICrusade";


registerLocale("pt-BR", ptBR);

const RegisterEditCrusade = () => {
  const location = useLocation();
  const [wordList, setWordList] = useState<IWord[]>(location.state?.crusade?.crusadeWords ? location.state?.crusade?.crusadeWords: []);
  const [createTitle, setCreateTitle] = useState<string | undefined>(location.state?.crusade?.title);
  const [createLevel, setCreateLevel] = useState<string | undefined>(location.state?.crusade?.difficultyLevel ? location.state?.crusade?.difficultyLevel: "Facil");
  const [wordId, setWordId] = useState<number>();
  const [objWord, setObjWord] = useState<IWord>({id: 0, word: "", tip: ""});
  const [word, setWord] = useState<string>("");
  const [tip, setTip] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [crusade, setCrusade] = useState<ICrusade>(location.state?.crusade);
  const navigate = useNavigate();


  const handleBackCrusades = () => {
    navigate("/list-crusades");
  };


  const handleClickSave = (e: React.FormEvent, crusade: ICrusade) => {
    e.preventDefault();
    let data = {
      title: createTitle,
      difficultyLevel: createLevel,
      wordList: wordList,
    };
    let wordRegex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/;

    let verifyWord: boolean = wordList.every((obj) => {
      return (
        obj.word &&
        wordRegex.test(obj.word) 
      );
    });

    if (verifyWord) {
      if (crusade !== undefined && crusade) {
        crusadeService
          .update(data, crusade.id)
          .then((response) => {
            alert("Cruzada alterada com sucesso");
          })
          .catch((error) => {
            alert("Não foi possível cruzar as palavras");
            setError(true);
          });
      } else {
        if (wordList.length >= 5 && wordList.length <= 20) {
          crusadeService
            .create(data)
            .then((response) => {
              alert("Cruzada criada com sucesso");
            })
            .catch((error) => {
              alert("Não foi possível cruzar as palavras");
              setError(true);
            });
        } else {
          alert(
            "Adicionar 5 palavras ou mais, com o limite máximo de 20 palavras por cruzada"
          );
        }
      }
    } else {
      alert("As palavras e dicas só podem conter letras");
    }
  };

  const buttonSaveModalEditOrCreate = (w: string, t: string) => {
    if (wordId) {
      let words = wordList;
      words = words.map((w) => {
        if (w.id === wordId) {
          w.word = word;
          w.tip = tip;
        }
        return w;
      });
      setWordList(words);
    } else {
      let obj = { id: 0, word: word, tip: tip };
      setObjWord(obj);
      setWordList([...wordList, obj]);
      setWord("");
      setTip("");
      setShow(false);
    }
    setWord("");
    setTip("");
    setShow(false);
  };

  const buttonUpdateFront = (crusadeWord: IWord, e: React.FormEvent) => {
    e.preventDefault();

    setShow(true);
    setWordId(crusadeWord.id);
    setWord(crusadeWord.word);
    setTip(crusadeWord.tip);
  };

  const deleteObjWordFront = (word: IWord, e: React.FormEvent) => {
    e.preventDefault();

    const deleteObj = wordList.filter((obj) => obj.word !== word.word);
    setWordList(deleteObj);
  };

  const handleClose = () => {
    setWord("");
    setTip("");
    setShow(false);
  };

  const handleShow = (e: React.FormEvent) => {
    e.preventDefault();
    setShow(true);
    setError(false);
  };
  
  return (
    <>
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>{crusade ? "Alterar" : "Cadastrar"} Cruzadas</h3>
            </div>
          </div>
          <Form>
            <Form.Group className="mb-2 col-3">
              <Form.Label htmlFor="inputNameCustomer">Título</Form.Label>
              <Form.Control
                type="text"
                placeholder="Título"
                value={createTitle}
                onChange={(e) => setCreateTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1 col-3">
              <Form.Label htmlFor="inputNameCustomer">Nível</Form.Label>
              <Form.Select onChange={(e) => setCreateLevel(e.target.value)}>
                {
                createLevel === "Facil" && (
                  <>
                   
                    <option selected value={CrusadeLevelType.FACIL}>
                      Fácil
                    </option>
                    <option value={CrusadeLevelType.MEDIO}>Médio</option>
                    <option value={CrusadeLevelType.DIFICIL}>Difícil</option>
                  </>
                )}

                {createLevel === "Medio" && (
                  <>
                    <option value={CrusadeLevelType.FACIL}>Fácil</option>
                    <option selected value={CrusadeLevelType.MEDIO}>
                      Médio
                    </option>
                    <option value={CrusadeLevelType.DIFICIL}>Difícil</option>
                  </>
                )}
                {createLevel === "Dificil" && (
                  <>
                    <option value={CrusadeLevelType.FACIL}>Fácil</option>
                    <option value={CrusadeLevelType.MEDIO}>Médio</option>
                    <option selected value={CrusadeLevelType.DIFICIL}>
                      Difícil
                    </option>
                  </>
                )}
                {createLevel !== "Facil" &&
                  createLevel !== "Medio" &&
                  createLevel !== "Dificil" && (
                    <>
                      <option selected value={CrusadeLevelType.FACIL}>
                        Fácil
                      </option>
                      <option value={CrusadeLevelType.MEDIO}>Médio</option>
                      <option value={CrusadeLevelType.DIFICIL}>Difícil</option>
                    </>
                  )}
              </Form.Select>
             
            </Form.Group>
            <div className="col-12">
              <Button
                type="submit"
                className="btn btn-primary float-end col-1"
                onClick={(e) => handleShow(e)}
              >
                +
              </Button>
            </div>
            <br />
            <br />
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>{crusade ? "Alterar" : "Cadastrar"} Palavra</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-1">
                    <Form.Label htmlFor="inputNameCustomer">Palavra</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Palavra"
                      value={word}
                      onChange={(e) => setWord(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label htmlFor="inputNameCustomer">Dica</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Dica"
                      value={tip}
                      onChange={(e) => setTip(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => buttonSaveModalEditOrCreate(word, tip)}
                >
                  Salvar
                </Button>
              </Modal.Footer>
            </Modal>
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr className="tr">
                    <th>Palavra</th>
                    <th>Dica</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {crusade !== undefined
                    ? wordList.map((word: IWord) => (
                        <tr key={word.id}>
                          <td>
                            <span>{word.word}</span>
                          </td>
                          <td>
                            <span>{word.tip}</span>
                          </td>
                          <td style={{ width: 250, paddingLeft: 15 }}>
                            <button
                              className="btn btn-primary"
                              style={{ marginRight: 10, marginLeft: 10 }}
                              onClick={(e) => buttonUpdateFront(word, e)}
                            >
                              Atualizar
                            </button>
                            <button
                              className="btn btn-danger"
                              style={{ marginRight: 10, marginLeft: 10 }}
                              onClick={(e) => deleteObjWordFront(word, e)}
                            >
                              Deletar
                            </button>
                          </td>
                        </tr>
                      ))
                    : wordList.map((word: IWord) => (
                        <tr key={word.id}>
                          <td>
                            <span>{word.word}</span>
                          </td>
                          <td>
                            <span>{word.tip}</span>
                          </td>
                          <td style={{ width: 200, paddingLeft: 15 }}>
                            <button
                              className="btn btn-danger"
                              style={{ marginRight: 10, marginLeft: 45 }}
                              onClick={(e) => deleteObjWordFront(word, e)}
                            >
                              Deletar
                            </button>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
              <Button
                style={{ marginRight: 2, marginLeft: 15, marginTop: 10 }}
                type="submit"
                className="btn btn-primary float-end col-1"
                onClick={(e) => handleClickSave(e, crusade)}
              >
                Salvar
              </Button>
              <Button
                variant="secondary"
                style={{ marginTop: 10 }}
                type="button"
                className="btn btn-primary float-end col-1"
                onClick={handleBackCrusades}
              >
                Cancelar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default RegisterEditCrusade;
