import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert, FormLabel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { PatternFormat } from "react-number-format";
import schoolService from "../../services/SchoolService";
import ISchool from "../../interfaces/ISchool";
import { UserType } from "../../enums/UserType";
import ICities from "../../interfaces/ICities";

registerLocale("pt-BR", ptBR);

const SchoolList = () => {
  const [items, setItems] = useState<ISchool[]>([]);
  const [showSchools, setShowSchools] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [schoolId, setSchoolId] = useState<number>();
  const [name, setName] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [aditionalAddress, setAditionalAddress] = useState<string>();
  const [number, setNumber] = useState<string>();
  const [cep, setCep] = useState<string>();
  const [city, setCity] = useState<number>();
  const [state, setState] = useState<string>();
  const [neighborhood, setNeighborhood] = useState<string>();
  const [responsible, setResponsible] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordCompare, setPasswordCompare] = useState<string>();
  const [type, setType] = useState<string | undefined>("SCHOOL");
  const [phone, setPhone] = useState<string>();
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [passError, setPassError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [allCities, setCities] = useState<ICities[]>([]);
  const dataStates = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  // const dataCitiesFake = [
  //   {
  //     id: 1,
  //     name: "Atibaia",
  //     state: "RJ",
  //   },
  //   {
  //     id: 3177,
  //     name: "Bom Jesus do Itabapoana",
  //     state: "RJ",
  //   },
  //   {
  //     id: 3178,
  //     name: "Italva",
  //     state: "RJ",
  //   },
  // ];

  const loadList = (state?: string, city?: string) => {
    if (state && state !== "") {
      if (city && city !== "") {
        // console.log('caiu no else')
        setLoading(true);
        schoolService
          .getAll(page, state, city)
          .then((response) => {
            let schools: ISchool[] = response.data;
            setItems(schools);
            setLoading(false);
          })
          .catch((error: any) => {
            setLoading(false);
          });
      }
      setLoading(true);
      schoolService
        .getCities(page, state)
        .then((response) => {
          let list: ICities[] = response?.data;
          setCities(list);
          setItems([]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      console.log("else, state n existe");
      // console.log('caiu no else')
      setLoading(true);
      schoolService
        .getAll(page)
        .then((response) => {
          let schools: ISchool[] = response.data;
          setItems(schools);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    loadList();
  }, [page]);


  const handleShowUpdate = (user: ISchool) => {
    setSchoolId(user.id);
    setName(user.name);
    setAddress(user.address);
    setNumber(user.address_number);
    setNeighborhood(user.neighborhood);
    setCep(user.postal_code);
    setCity(user.city.id);
    setAditionalAddress(user.address_additional);
    setResponsible(user.responsible_name);
    setState(user.state);
    if (email) {
      setEmail(user.email);
    }
    setType(user.type);
    setPhone(user.phone);
    handleShow();
  };

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
    var emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (email) {
      if (email && emailRegex.test(email)) {
        let data = {
          name: name,
          address: address,
          address_number: number,
          neighborhood: neighborhood,
          postal_code: cep,
          city: city,
          address_additional: aditionalAddress,
          responsible_name: responsible,
          email: email,
          state: state,
          phone: phone,
          type: type,
        };
        if (schoolId) {
          schoolService
            .update(data, schoolId)
            .then((response) => {
              loadList();
              handleClose();
              setSchoolId(undefined);
            })
            .catch((error) => {
              setError(true);
            });
        } else {
          schoolService
            .create(data)
            .then((response) => {
              loadList();
              handleClose();
            })
            .catch(() => {
              setError(true);
            });
        }
      } else {
        setPassError(true);
      }
    } else {
      let data = {
        name: name,
        address: address,
        address_number: number,
        neighborhood: neighborhood,
        postal_code: cep,
        city: city,
        address_additional: aditionalAddress,
        responsible_name: responsible,
        state: state,
        phone: phone,
        type: type,
      };

      if (schoolId) {
        schoolService
          .update(data, schoolId)
          .then((response) => {
            loadList();
            handleClose();
            setSchoolId(undefined);
          })
          .catch((error) => {
            setError(true);
          });
      } else {
        schoolService
          .create(data)
          .then((response) => {
            loadList();
            handleClose();
          })
          .catch(() => {
            setError(true);
          });
      }
      setEmailError(true);
    }
  };

  const handleDelete = async (id: number) => {
    schoolService
      .delete(id)
      .then((response) => {
        loadList();
        handleClose();
      })
      .catch((error) => {
        alert(
          "Não foi possível excluir a escola porque possui dados vinculados no sistema"
        );
      });
  };

  const handleClose = () => {
    setSchoolId(undefined);
    setName(undefined);
    setAddress(undefined);
    setNumber(undefined);
    setNeighborhood(undefined);
    setCep(undefined);
    setCity(undefined);
    setAditionalAddress(undefined);
    setResponsible(undefined);
    setState(undefined);
    if (email) {
      setEmail(undefined);
    }
    setPhone(undefined);
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    setError(false);
    setPassError(false);
  };

  const handleCloseUsers = () => {
    setShowSchools(false);
  };

  const getUserType = (type: string) => {
    const types: { [key: string]: string } = {
      ADMIN: "Administrador",
      STUDENT: "Estudante",
      SCHOOL: "Escola",
    };
    return types[type];
  };

  const handleStateChange = (selectedState: string) => {
    setSelectedState(selectedState);
    loadList(selectedState);
  };

  const handleCityChange = (selectedCity: string) => {
    // console.log(selectedCity, 'city')
    setSelectedCity(selectedCity);
    loadList(selectedState, selectedCity)
  };

  return (
    <>
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Escolas</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={handleShow}>
              Cadastrar escola
            </Button>
          </div>
          <div>
            <Form.Group className="mb-2">
              <Form.Select
                value={selectedState}
                onChange={(e) => handleStateChange(e.target.value)}
              >
                <option value="">Selecione um Estado</option>
                {dataStates.map((state) => (
                  <option key={state}>{state}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
          <div>
            <Form.Group className="mb-2">
              <Form.Select
                value={selectedCity}
                onChange={(e) => handleCityChange(e.target.value)}
              >
                <option value="">Selecione uma cidade</option>
                {allCities &&
                  allCities.map((city) => (
                    <option key={city.id}>{city.name}</option>
                  ))}
              </Form.Select>
            </Form.Group>
          </div>
          <br />
          <Table striped bordered hover>
            <thead>
              <tr className="tr">
                <th>Nome</th>
                <th>Endereço</th>
                <th>Número</th>
                <th>Bairro</th>
                <th>Cep</th>
                <th>Cidade</th>
                <th>Estado</th>
                <th>Endereço Adicional</th>
                <th>Responsável</th>
                <th>Telefone</th>
                <th>Tipo</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {items.map((user: ISchool) => (
                <tr key={user.id}>
                  <td style={{ width: 100 }}>
                    <span>{user.name}</span>
                  </td>
                  <td>
                    <span>{user.address}</span>
                  </td>
                  <td style={{ width: 100 }}>
                    <span>{user.address_number}</span>
                  </td>
                  <td>
                    <span>{user.neighborhood}</span>
                  </td>
                  <td style={{ width: 100 }}>
                    <span>{user.postal_code}</span>
                  </td>
                  <td>
                    <span>{user.city?.name}</span>
                  </td>
                  <td>
                    <span>{user.state}</span>
                  </td>
                  <td>
                    <span>{user.address_additional}</span>
                  </td>
                  <td style={{ width: 130 }}>
                    <span>{user.responsible_name}</span>
                  </td>
                  <td>
                    <span>{user.phone}</span>
                  </td>
                  <td>
                    <span>{getUserType(user.type)}</span>
                  </td>
                  <td style={{ width: 550, paddingLeft: 15 }}>
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={(e) => handleShowUpdate(user)}
                    >
                      Atualizar
                    </button>
                    <button
                      className="btn btn-danger"
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={(e) => handleDelete(user.id)}
                    >
                      Deletar
                    </button>
                  </td>
                </tr>
              ))}
              {items.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Nenhuma escola encontrada.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
            <Button disabled={page === 0} className="btn btn-primary m-2" onClick={() => setPage(page - 1)}>
            Anterior
            </Button>
            <Button  className="btn btn-primary m-2" onClick={() => setPage(page + 1)}>
              Próxima
            </Button>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar Escola</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Nome</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Endereço</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Endereço"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Número</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Número"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Bairro</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Bairro"
                value={neighborhood}
                onChange={(e) => setNeighborhood(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">CEP</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="CEP"
                value={cep}
                onChange={(e) => setCep(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Cidade</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Cidade"
                value={city}
                onChange={(e) => setCity(Number(e.target.value))}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Estado</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Estado"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">
                Endereço Adicional
              </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Endereço adicional"
                value={aditionalAddress}
                onChange={(e) => setAditionalAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Responsável</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Responsável"
                value={responsible}
                onChange={(e) => setResponsible(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Telefone</Form.Label>
              <PatternFormat
                required
                format="(##)#####-####"
                allowEmptyFormatting
                mask="_"
                displayType="input"
                onValueChange={(values) => {
                  const { value } = values;
                  setPhone(value);
                }}
                step={0.1}
                className="form-control"
                name="tax"
                placeholder="(00)00000-0000"
                value={phone}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Tipo</Form.Label>
              <Form.Select onChange={(e) => setType(e.target.value)}>
                <option value={UserType.SCHOOL}>Escola</option>
              </Form.Select>
            </Form.Group>
          </Form>
          {error && (
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              Escola já cadastrada
            </Alert>
          )}
          {emailError && email !== undefined && (
            <Alert
              variant="danger"
              onClose={() => setEmailError(false)}
              dismissible
            >
              E-mail inválido
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              setSchoolId(undefined);
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => handleClick(e)}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showSchools} onHide={handleCloseUsers}>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUsers}>
            Cancelar
          </Button>
          {
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={handleClick}
            >
              Salvar
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SchoolList;
