import api from "./api"

class CrusadeService{

    async getAll(): Promise<any>{   
        return api.get(`/crusade/all`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async create(data: any): Promise<any>{
        return api({
            url: "/crusade/create",
            method: "POST",
            timeout: 5000,
            data: data,
            headers: {Accept: 'application/json'}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async update(data: any, id: any): Promise<any>{
        return api({
            url: `/crusade/update/${id}`,
            method: "PUT",
            timeout: 5000,
            data: data,
            headers: {Accept: 'application/json'}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }


    async delete(id: any): Promise<any>{   
        return api.delete(`/crusade/delete/${id}`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }
}
        
const crusadeService = new CrusadeService()
export default crusadeService;
