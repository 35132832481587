import "./App.css";
import React from "react";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";
import api from "./services/api";
import tokenService from "./services/TokenService";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import AdminRoute from "./components/adminRoute/AdminRoute";
import Login from "./pages/Login";
import UserList from "./pages/UserList";
import AppLayout from "./components/layout/AppLayout";
import Menu from "./pages/Menu";
import SchoolList from "./pages/SchoolList";
import WordGallowsList from "./pages/Word_Gallows";
import QuizQuestionsList from "./pages/QuizQuestions";
import CrusadeList from "./pages/Crusade";
import RegisterEditCrusade from "./pages/RegisterEditCrusade";

function App() {
  useEffect(() => {
    api.interceptors.request.use(async (config: any) => {
      if (config.url !== "/user/login") {
        let token = window.sessionStorage.getItem("hash");
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
      }
      return config;
    });

    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          const originalReq = err.config;
          // Inverter o if
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config._retry &&
            !err.config.url.endsWith("login")
          ) {
            originalReq._retry = true;
            let token = window.sessionStorage.getItem("hash");
            if (token === null) return;
            let res = tokenService
              .refresh({ oldToken: token })
              .then((res) => {
                console.clear();
                if (res.data.access_token) {
                  window.sessionStorage.setItem("hash", res.data.access_token);
                }
                originalReq.headers[
                  "Authorization"
                ] = `Bearer ${res.data.access_token}`;
                return axios(originalReq);
              })
              .catch((error) => console.log(error));
            resolve(res);
          } else {
            reject(err);
          }
        });
      }
    );
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AppLayout />}>
        <Route path="/menu" element={<PrivateRoute element={<Menu />} />} />
          <Route
            path="/list-users"
            element={<AdminRoute element={<UserList />} />}
          />
          <Route
            path="/list-schools"
            element={<AdminRoute element={<SchoolList />} />}
          />
           <Route
            path="/list-words-gallows"
            element={<AdminRoute element={<WordGallowsList />} />}
          />
           <Route
            path="/list-quiz-questions"
            element={<AdminRoute element={<QuizQuestionsList />} />}
          />
          <Route
            path="/list-crusades"
            element={<AdminRoute element={<CrusadeList />} />}
          />
           <Route
            path="/registerCrusade"
            element={<AdminRoute element={<RegisterEditCrusade />} />}
          />                      
        </Route>
        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
