import { SrvRecord } from "dns";
import api from "./api";

class SchoolService {
  async getCities(page: number, state?: string): Promise<any> {
    try {
      const params = state ? {page: page, state: state } : {page: page};
      return api
        .get("city/cities-in-state", { params })
        .then((result) => {
            console.log(result, 'result service front')
          return Promise.resolve(result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    } catch (error) {
        console.error("Error from API:", error);
      return Promise.reject(error);
    }
  }

  async getAll(page: number, state?: string, city?: string): Promise<any> {
    try {
      const params = state && city ? {page: page, state: state, city: city } : {page: page};
      return api
        .get("school/all", {params})
        .then((result) => {
            console.log(result, 'result service front')
          return Promise.resolve(result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    } catch (error) {
        console.error("Error from API:", error);
      return Promise.reject(error);
    }
  }

  async create(data: any): Promise<any> {
    return api({
      url: "/school/create",
      method: "POST",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async update(data: any, id: any): Promise<any> {
    return api({
      url: `/school/update/${id}`,
      method: "PUT",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async delete(id: any): Promise<any> {
    return api
      .delete(`/school/delete/${id}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const schoolService = new SchoolService();
export default schoolService;
