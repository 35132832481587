import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { PatternFormat } from "react-number-format";
import userService from "../../services/UserService";
import IUser from "../../interfaces/IUser";
import { UserType } from "../../enums/UserType";
import { toBeDisabled } from "@testing-library/jest-dom/matchers";

registerLocale("pt-BR", ptBR);

const UserList = () => {
  const [items, setItems] = useState<IUser[]>([]);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [userId, setUserId] = useState<number>();
  const [name, setName] = useState<string>();
  const [school, setSchool] = useState<number>();
  const [year, setYear] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordCompare, setPasswordCompare] = useState<string>();
  const [type, setType] = useState<string | undefined>("ADMIN");
  const [phone, setPhone] = useState<string>();
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [passError, setPassError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [page, setPage] = useState<number>(0);


  const loadList = (search?: string) => {

    setLoading(true);

    if(search){
      // console.log('passou no if do search front')
      userService
      .getAll(page, search)
      .then((response) => {
        // console.log('CAIU NO SEARCH'); 
        let users: IUser[] = response.data;
        setItems(users);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    }else{
      userService
      .getAll(page)
      .then((response) => {
        // console.log('response:', response.data); 
        let users: IUser[] = response.data;
        setItems(users);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    }
   
  };

  useEffect(() => {
    loadList();
  }, [page]);

  const handleShowUpdate = (user: IUser) => {
    setUserId(user.id);
    setName(user.name);
    setEmail(user.email);
    setYear(user.classroom);
    setSchool(user.school?.id);
    setType(user.type);
    setPhone(user.phone);
    setPassword("");
    setPasswordCompare("");
    handleShow();
  };

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
    var emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (email && emailRegex.test(email)) {
      let data = {
        name: name,
        email: email,
        classroom: year,
        username: email,
        school: school,
        type: type,
        phone: phone,
        password,
      };
      if (password === passwordCompare) {
        if (userId) {
          userService
            .update(data, userId)
            .then((response) => {
              loadList();
              handleClose();
              setUserId(undefined);
            })
            .catch((error) => {
              setError(true);
            });
        } else {
          data.password = password;
          userService
            .create(data)
            .then((response) => {
              loadList();
              handleClose();
            })
            .catch(() => {
              setError(true);
            });
        }
      } else {
        setPassError(true);
      }
    } else {
      setEmailError(true);
    }
  };

  const handleDelete = async (id: number) => {
    userService
      .delete(id)
      .then((response) => {
        loadList();
      })
      .catch((error) => {
        alert(
          "Não foi possível excluir o usuário porque possui dados vinculados no sistema"
        );
      });
  };

  const handleClose = () => {
    setSchool(undefined);
    setYear(undefined);
    setName(undefined);
    setUserId(undefined);
    setPassword(undefined);
    setType(undefined);
    setPhone(undefined);
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    setError(false);
    setPassError(false);
  };

  const handleCloseUsers = () => {
    setShowUsers(false);
  };

  const getUserType = (type: string) => {
    const types: { [key: string]: string } = {
      ADMIN: "Administrador",
      STUDENT: "Estudante",
    };
    return types[type];
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // console.log('passou aqui')
    if(search){
      loadList(search)
    }
  };

  return (
    <>
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Usuários</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={handleShow}>
              Cadastrar usuário
            </Button>
          </div>
          <div className="form-body">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-2">
                <Form.Control className="custom-form-control"
                  type="text"
                  placeholder="Pesquise por nome, telefone ou e-mail"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Form.Group>
              <Button type="submit" className="btn btn-primary m-2">
                Pesquisar
              </Button>
            </Form>
            </div>
          <br />
          <Table striped bordered hover>
            <thead>
              <tr className="tr">
                <th>Nome</th>
                <th>Escola</th>
                <th>Ano</th>
                <th>Telefone</th>
                <th>Email</th>
                <th>Tipo</th>
                <th>Usuário</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {items && items.map((user: IUser) => (
                <tr key={user.id}>
                  <td style={{ width: 120 }}>
                    <span>{user.name}</span>
                  </td>
                  <td>
                    <span>{user.school?.name}</span>
                  </td>
                  <td style={{ width: 100 }}>
                    <span>{user.classroom}</span>
                  </td>
                  <td>
                    <span>{user.phone}</span>
                  </td>
                  <td>
                    <span>{user.email}</span>
                  </td>
                  <td style={{ width: 100 }}>
                    <span>{getUserType(user.type)}</span>
                  </td>
                  <td style={{ width: 130 }}>
                    <span>{user.name}</span>
                  </td>
                  <td style={{ width: 250 }}>
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={(e) => handleShowUpdate(user)}
                    >
                      Atualizar
                    </button>
                    <button
                      className="btn btn-danger"
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={(e) => handleDelete(user.id)}
                    >
                      Deletar
                    </button>
                  </td>
                </tr>
              ))}
              {items && items.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Nenhum usuário encontrado.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Button disabled={page === 0} className="btn btn-primary m-2" onClick={() => setPage(page - 1)}>
            Anterior
            </Button>
            <Button  className="btn btn-primary m-2" onClick={() => setPage(page + 1)}>
              Próxima
            </Button>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Escola</Form.Label>
              <Form.Control
                type="text"
                placeholder="Código da escola"
                value={school}
                onChange={(e) => setSchool(Number(e.target.value))}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Ano</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ano"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">
                Confirme a senha
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Senha"
                value={passwordCompare}
                onChange={(e) => setPasswordCompare(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Telefone</Form.Label>
              <PatternFormat
                format="(##)#####-####"
                allowEmptyFormatting
                mask="_"
                displayType="input"
                onValueChange={(values) => {
                  const { value } = values;
                  setPhone(value);
                }}
                step={0.1}
                className="form-control"
                name="tax"
                placeholder="(00)00000-0000"
                value={phone}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Tipo</Form.Label>
              <Form.Select onChange={(e) => setType(e.target.value)}>
                {type === "ADMIN" && (
                  <>
                    <option selected value={UserType.ADMIN}>
                      Administrador
                    </option>
                    <option value={UserType.STUDENT}>Estudante</option>
                    <option value={UserType.SCHOOL}>Escola</option>
                  </>
                )}

                {type === "STUDENT" && (
                  <>
                    <option value={UserType.ADMIN}>Administrador</option>
                    <option selected value={UserType.STUDENT}>
                      Estudante
                    </option>
                    <option value={UserType.SCHOOL}>Escola</option>
                  </>
                )}
                {type === undefined && (
                  <>
                    <option value={UserType.ADMIN}>Administrador</option>
                    <option value={UserType.STUDENT}>Estudante</option>
                    <option value={UserType.SCHOOL}>Escola</option>
                  </>
                )}
              </Form.Select>
            </Form.Group>
          </Form>
          {passError && (
            <Alert
              variant="danger"
              onClose={() => setPassError(false)}
              dismissible
            >
              As senhas não coincidem
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              Usuário já cadastrado
            </Alert>
          )}
          {emailError && (
            <Alert
              variant="danger"
              onClose={() => setEmailError(false)}
              dismissible
            >
              E-mail inválido
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              setUserId(undefined);
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => handleClick(e)}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showUsers} onHide={handleCloseUsers}>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUsers}>
            Cancelar
          </Button>
          {
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={handleClick}
            >
              Salvar
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserList;
