import api from "./api";

class UserService {
  async getAll(page: number, searchTerm?: string, ): Promise<any> {
    // console.log(searchTerm, 'searchTerm')
    try {
      const params = searchTerm ? { page: page, searchTerm: searchTerm } : { page: page};
       return api
        .get(`/user/all`, { params })
        .then((result) => {

    //   console.log('RESULT', result)
          return Promise.resolve(result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });

    } catch (error) {
    //   console.error("Error from API:", error);
      return Promise.reject(error);
    }
  }

  async create(data: any): Promise<any> {
    return api({
      url: "/user/create",
      method: "POST",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async update(data: any, id: any): Promise<any> {
    return api({
      url: `/user/update/${id}`,
      method: "PUT",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async delete(id: any): Promise<any> {
    return api
      .delete(`/user/delete/${id}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const userService = new UserService();
export default userService;
